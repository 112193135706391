<template>
  <div>
    <h1>This is page Integrations</h1>
  </div>
</template>
<script>
export default {
  name: 'Integrations',
}
</script>
